<template>
	<div class="blig-pdf-wdt position-relative">
		<header class="brand-top-section position-relative">
			<div class="d-inline-block">
				<div class="lft-logopart">
					<img src="/images/billing-receipt.svg" class="img-fluid p-blg-toplogo" alt="Billing Receipt">
				</div>
				<div class="rgt-loct-part">
					<div class="text-end">
						<p class="mb-0 mdcal-cntr"><span class="fw-600" style="font-size: 8px;">DR.</span> {{
							this.patientProviderName }} |
							CPS ID - {{ this.providerSpecialtyCode }}<br>
							{{ this.organizationName }}</p>
						<p class="mb-0 cmpn-add" v-html="this.providerAddress"></p>
						<p class="cmpn-add mb-0">
							<span class="fw-600">TEL</span> {{ this.providerCellPhone }} <span
								class="fw-600 ms-2">FAX</span>
							{{ this.providerFaxPhone }}
						</p>
					</div>
				</div>
			</div>
		</header>
		<div class="divider-line"></div>
		<div class="position-relative">
			<div class="main-lft-brd">
				<img src="/images/polygon-2.svg" class="dots-polygon-01">
				<div class="billing-dtls">
					<p class="bill-to-nm mb-0">BILL TO <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
				</div>
				<div class="d-inline-block w-100">
					<div class="ptnt-blig-detls">
						<p class="ptnt-ttl mb-0">patient</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-700">{{ this.invoiceViewPatientName }} </p>
							<p class="mb-0">{{ this.patientAddress }}<br>
								<span v-if="this.patientCity">{{ this.patientCity }}, </span>{{ this.patientState }} {{ this.patientResidentZip }}<br>
								<span v-if="this.patientCellPhone"><span class="fw-600">Tel</span> {{ this.patientCellPhone }}</span>
							</p>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="ptnt-ttl mb-0">date of birth</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-700" v-if="this.advanceSettingData.dateOfBirth">{{ this.advanceSettingData.dateOfBirth }}</p>
						</div>
					</div>
					<div class="ptnt-blig-detls">
						<p class="ptnt-ttl mb-0">hin</p>
						<div class="loct-pt-rg">
							<p class="mb-0 fw-700" v-if="this.patientHcvDetails">{{ this.patientHcvDetails }}</p>
						</div>
					</div>
				</div>
				<div class="sevs-ttl">
					<p class="bill-to-nm mb-0">Services <img src="/images/arrow-cross.svg" class="crs-arrow"></p>
				</div>
				<div class="sevs-tablelist" style="min-height: 350px;">
					<table class="table">
						<thead>
							<tr>
								<th>date</th>
								<th>service description</th>
								<th class="text-end">quantity</th>
								<th class="text-end">price</th>
								<th class="text-end">total</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in serviceCodeDetails" :key="index">
								<td>{{ $filters.inputDateTime(item.updated_datetime, 'YYYY-MM-DD') }}</td>
								<td>{{ item.service_code_description }}</td>
								<td class="text-end">{{ item.number_of_service }}</td>
								<td class="text-end">${{ parseFloat(item.fee_submitted.replace(/,/g, '')).toFixed(2) }}
								</td>
								<td class="text-end">${{ (parseFloat(item.fee_submitted.replace(/,/g, '')) *
									item.number_of_service).toFixed(2) }}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="sevs-tablelist totalbilng-prc">
					<table class="table">
						<tbody>
							<tr>
								<td colspan="4">Subtotal</td>
								<td class="text-end fw-600">${{ subTotal }}</td>
							</tr>
							<tr>
								<td colspan="4">HST</td>
								<td class="text-end fw-600">$0.00</td>
							</tr>
							<tr style="border-top: 1px dashed rgb(0 0 0 / 25%);">
								<td class="fw-600" colspan="4" style="padding-top: 10px;">Total</td>
								<td class="text-end fw-600" style="padding-top: 10px;">${{ subTotal }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="position-relative" style="top: 23px;">
			<div class="and-brd-w"></div>
			<div class="and-brd-h"></div>
			<img src="/images/polygon-2.svg" class="dots-polygon">
		</div>
		<div class="docttypesect text-end" style="right: 10px;">
			<p class="mb-0 doct-gnrt-tag">Document Generated By |</p>
			<img src="/images/verismo-apps-logo.svg" class="img-fluid">
		</div>
	</div>

</template>

<script>
import axios from "@/scripts/axios.js";
// import $ from "jquery";

export default ({
	data() {
		return {
			provider_detail: [],
			serviceCodeDetails: [],
			serviceCodeTotalFees: null,
			invoiceDetailsData: [],
			previousInvoiceData: [],
			advanceSettingData: {
				healthNumber: '',
				versionCode: '',
				provinceCode: '',
				claimPayee: '',
				lastName: '',
				firstName: '',
				dateOfBirth: '',
				gender: '',
				claimType: '',
			},
			invoiceViewPatientName: '',
			patientDateOfBirth: '',
			patientAge: null,
			patientHcvDetails: '',
			patientHCV: '',
			patientAddress: '',
			patientCity: '',
			patientState: '',
			patientResidentZip: '',
			patientCellPhone: '',
			patientProviderId: null,
			patientProviderName: '',

			providerAddress: '',
			providerCellPhone: '',
			providerFaxPhone: '',
			providerSpecialtyCode: null,
			organizationName: '',

			retrieveInvoiceId: null,
			retrieveInvoiceType: '',
			subTotal: '',
		}
	},
	methods: {
		printPreview() {
			this.$store.state.loader = false;
			const invoiceId = this.$route.params.id;
			const type = this.$route.params.type;
			axios.post('invoice/retrieve', { 'invoice_id': invoiceId, 'type': type })
				.then((response) => {
					if (response.status == 200) {
						this.retrieveInvoiceId = invoiceId;
						this.retrieveInvoiceType = type;
						this.serviceCodeDetails = response.data.data.invoice_details;
						this.serviceCodeTotalFees = response.data.data.total;
						this.invoiceDetailsData = response.data.data.invoice_details_data;
						this.previousInvoiceData = response.data.data.previous_invoice_data;

						this.subTotal = this.serviceCodeDetails.reduce((total, item) => {
							const feeSubmitted = parseFloat(item.fee_submitted.replace(/,/g, ''));
							return total + (item.number_of_service * feeSubmitted);
						}, 0).toFixed(2);

						this.invoiceViewPatientName = this.$filters.strToUpperCase(this.invoiceDetailsData.patient_name);
						this.patientDateOfBirth = this.invoiceDetailsData.dob;
						this.patientAge = this.$filters.patientCalculatedAge(this.invoiceDetailsData.dob);
						this.patientHCV = (this.invoiceDetailsData.is_hcv_verified == 1) ? "(ON)" : "(OFF)";
						if (this.invoiceDetailsData.health_insurance_no) {
							this.patientHcvDetails = [
								this.invoiceDetailsData.health_insurance_no,
								this.invoiceDetailsData.health_card_ver,
								this.patientHCV
							]
							.filter(Boolean)
							.map(this.$filters.strToUpperCase)
							.join(' ');
						} else {
							this.patientHcvDetails = null;
						}
						this.patientProviderId = this.invoiceDetailsData.provider_id;
						this.patientProviderName = this.invoiceDetailsData.provider_name;
						this.patientAddress = (this.invoiceDetailsData.residential_address && this.invoiceDetailsData.residential_address != "") ? this.invoiceDetailsData.residential_address : '';
						this.patientState = (this.invoiceDetailsData.residential_state && this.invoiceDetailsData.residential_state != "") ? this.invoiceDetailsData.residential_state : '';
						this.patientCity = (this.invoiceDetailsData.residential_city && this.invoiceDetailsData.residential_city != "") ? this.invoiceDetailsData.residential_city : '';
						this.patientResidentZip = (this.invoiceDetailsData.residential_zip && this.invoiceDetailsData.residential_zip != "") ? this.invoiceDetailsData.residential_zip : '';
						this.patientCellPhone = (this.invoiceDetailsData.cell_phone && this.invoiceDetailsData.cell_phone != "") ? this.invoiceDetailsData.cell_phone : '';

						this.providerAddress = (this.invoiceDetailsData.provider_address != "") ? this.invoiceDetailsData.provider_address : '';
						this.providerSpecialtyCode = (this.invoiceDetailsData.provider_specialty_code != "") ? this.invoiceDetailsData.provider_specialty_code : '';
						this.providerCellPhone = (this.invoiceDetailsData.provider_cell_phone != "") ? this.invoiceDetailsData.provider_cell_phone : '';
						this.providerFaxPhone = (this.invoiceDetailsData.provider_fax_phone != "") ? this.invoiceDetailsData.provider_fax_phone : '';
						this.organizationName = (this.invoiceDetailsData.organization_name != "") ? this.invoiceDetailsData.organization_name : '';

						this.advanceSettingData.healthNumber = this.$filters.strToUpperCase(this.invoiceDetailsData.health_insurance_no);
						this.advanceSettingData.versionCode = this.$filters.strToUpperCase(this.invoiceDetailsData.health_card_ver);
						this.advanceSettingData.dateOfBirth = this.invoiceDetailsData.dob;

						if (this.invoiceDetailsData.state_name && this.invoiceDetailsData.state_sort_name) {
							this.advanceSettingData.provinceCode = this.invoiceDetailsData.state_sort_name + ' - ' + this.invoiceDetailsData.state_name;
						} else if (this.invoiceDetailsData.state_name) {
							this.advanceSettingData.provinceCode = this.invoiceDetailsData.state_name;
						}
						if (this.invoiceDetailsData.claim_payee) {
							if (this.invoiceDetailsData.claim_payee == "P") {
								this.advanceSettingData.claimPayee = this.invoiceDetailsData.claim_payee + " - Provider";
							} else if (this.invoiceDetailsData.claim_payee == "S") {
								this.advanceSettingData.claimPayee = this.invoiceDetailsData.claim_payee + " - Patient";
							}
						}
						if (this.invoiceDetailsData.gender) {
							switch (this.invoiceDetailsData.gender) {
								case 'M':
									this.advanceSettingData.gender = 'Male';
									break;
								case 'F':
									this.advanceSettingData.gender = 'Female';
									break;
								case 'O':
									this.advanceSettingData.gender = 'Other';
									break;
								case 'T':
									this.advanceSettingData.gender = 'Trans';
									break;

								default:
									break;
							}
						}
						this.advanceSettingData.firstName = this.$filters.strToUpperCase(this.invoiceDetailsData.first_name);
						this.advanceSettingData.lastName = this.$filters.strToUpperCase(this.invoiceDetailsData.last_name);
						this.$store.state.loader = false;
						setTimeout(() => {
							window.print();
						}, 200);
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
						console.log(Object.values(error.response.data.data).join(', '))
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
	},
	mounted() {
		setTimeout(() => {
			this.printPreview();
		}, 200);
	}
})
</script>